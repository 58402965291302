@import url("https://fonts.googleapis.com/css2?family=Italiana&display=swap");
body {
  width: 100%;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-top: 104px !important;
   color: #250e62;
  font-smooth: inherit;
  font-smooth: initial;
  font-smooth: revert;
  font-smooth: revert-layer;
  font-smooth: unset;
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Italiana", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media screen and (max-width: 992px) {
  body {
    margin-top: 0px !important;
  }
}
@font-face {
  font-family: "CustomFont";
  src: url("../src/fonts/OptimaLTPro-DemiBold.otf");
  src: url("../src/fonts/OptimaLTPro-DemiBold.woff") format("woff");
  src: url("../src/fonts/OptimaLTPro-DemiBold.woff2") format("woff2");
}
::selection {
    background-color: #209CEE ;
    color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ul {
  text-align: left;
}
p {
  color: #371e7a!important;
  line-height: 1.6;
}

h5 {
  font-weight: "Roboto", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
small {
  font-weight: 400 !important;
  line-height: 1.1;
  color: #250e62 !important;
  margin: 0;
  font-family: "CustomFont" !important;
}
span1 {
  font-family: "Italiana" !important;
  color: #209CEE ;
}
h1 {
  font-size: 2.5rem;
}
small {
  font-size: 18px;
   color: #250e62;
}
a {
  text-decoration: none !important;
   color: #250e62;
  font-weight: 500;
  font-size: 14px;
}
strong {
  font-weight: 300 !important;
  font-size: 25px;
  line-height: 1;
   color: #250e62;
}
.__wrap {
  max-width: 1400px;
  padding: 0px 20px;
}
.max-width {
  width: 100% !important;
  max-width: 1300px;
  height: auto;
  flex-direction: column;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: white !important;
}
@media only screen and (max-width: 992px) {
  h1 {
    font-size: 2.5rem !important;
    line-height: 1 !important;
  }
  .max-width {
    width: 100%;
    max-width: none !important;
  }
}

@media only screen and (max-width: 992px) {
  body {
    margin-top: 80px !important;
  }
}
