.cart {
 height: 80vh;
  overflow-y: scroll !important;
  .close {
    button {
      border: none;
      outline: none !important;
      background-color: white !important;
      .close_icon {
        font-size: 18px !important;
        color: gray;
      }
      .close_icon:hover {
        color: #000000;
      }
    }
  }
  .delivary_cond {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    padding: 10px;
  }
  .freeDel {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #209CEE ;
    margin-bottom: 10px;
    span {
      font-size: 10px;
      font-weight: 400;
      strong {
        font-size: 12px;
        font-weight: 800 !important;
      }
    }
  }
  position: absolute;
  right: 0px;
  top: 60px;
  width: 100%;
  max-width: 400px;
  z-index: 99999;
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.914);
  padding: 20px;
  -webkit-box-shadow: 0px 0px 7px -5px rgba(0, 0, 0, 0.882);
  box-shadow: 0px 0px 7px -5px rgb(0, 0, 0);
  .cart-head {
    margin-bottom: 10px;
    color: gray;
    font-weight: 400;
    font-size: 24px;
  }
  h2 {
    margin-bottom: 10px;
    color: gray;
    font-weight: 400;
    font-size: 24px;
  }

  .item {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid rgb(235, 235, 235);
    img {
      width: 80px;
      height: 100px;
      object-fit: cover;
    }

    .details {
      h1 {
        font-size: 18px;
        font-weight: 500;
      }

      p {
        color: gray;
        margin-bottom: 10px;
        font-size: 14px;
      }

      .price {
        span {
          color: #209CEE ;
          size: 10px !important;
        }
      }
    }

    .delete {
      color: red;
      font-size: 30px;
      cursor: pointer;
    }
  }

  .total {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 10px;
    color: grey;
  }
  .sub_total {
    display: flex;
    justify-content: space-between;
    font-weight: 800;
    font-size: 18px;
    margin-bottom: 10px;
   
  }
  .delivaryMsg{
    margin-bottom: 10px;
    width: 100%;
border-radius: 5px;
background-color: #fff6be;
    padding: 5px;
    span{
      font-size: 12px;
      color: red;
      font-weight: 600;
    }
  }

  .checkout {
    width: 250px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    cursor: pointer;
    border: none;
    background-color: #209CEE ;
    color: white;
    font-weight: 500;
    margin-bottom: 20px;
    border-radius: 30px;
  }
  .checkout:hover {
    transition: all 0.3s ease-in-out;
    background-color: #7bbd42;
  }

  .reset {
    color: red;
    font-size: 12px;
    cursor: pointer;
  }

  .textOrg {
    color: rgb(0, 0, 0);
  }
  .delivery-charge {
    color: red;
    text-decoration: line-through;
  }

  .hide {
    display: none;
  }
}
