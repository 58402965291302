.card {
  border: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
  padding: 8px;

  .card_in {
    align-items: center;
    justify-content: center;
    width: 100%;
    display: flex !important;
    flex-direction: column;
    border: 0.5px solid rgb(246, 246, 246);
    border-radius: 10px;
    padding: 10px;
    .image {
      object-fit: contain;
      height: 250px;
      margin: auto;
      width: 250px;
      position: relative;
      align-items: center;
      justify-content: center;
      display: flex;

      &:hover {
        .secondImg {
          z-index: 2;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
      }

      .mainImg {
        z-index: 1;
      }

      span {
        position: absolute;
        top: 5px;
        left: 5px;
        background-color: orange;
        color: rgb(255, 255, 255);
        padding: 3px 5px;
        z-index: 3;
        border-radius: 5px 5px 0px 5px;
        font-weight: 400;
        font-size: 18px;
      }
      span2{
       position: absolute;
      top: 5px;
      left: 5px;
      background-color: rgb(255, 255, 255);
      color: rgb(255, 0, 0);
      padding: 3px 5px;
      z-index: 3;
      border-radius: 5px 5px  0px 5px;
      font-weight: 400;
      font-size: 18px;

    }
    }
  }

  h2 {
    font-size: 16px;
    font-weight: 400;
  }

  .prices {
    display: flex;
    gap: 20px;
    h1 {
      font-size: 30px;
    }

    h2 {
      font-size: 18px;
      font-weight: 500;

      &:first-child {
        color: rgb(255, 0, 0);
        text-decoration: line-through;
      }
    }
  }
}
